.bank-box {
    background-color: #f1f3f4;
    border-radius: 10px;
    padding: 10px;
    .btn-copy {
        background-color: white;
        padding: 5px;
        border-radius: 10px;
        &:hover {
            cursor: pointer;
        }
    }

    .copy-bank-account {
        all: unset;
    }
}
