.filterContainer {
    border-bottom-color: gray;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    padding: 10px;
    width: 100%;
}
.row {
    display: flex;
    flex-direction: row;
}

.rowCenter {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.rowInput {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
}
.inputBox {
    padding:10px 5px;
    border-width: 1px;
    border-radius: 10px;
    border-style: solid;
    align-items: center;
    border-color: gray;
    display:flex;
    flex-direction: row;
}
.boxArea {
    align-items: center;
    border-radius: 10px;
    padding: 5px;
    margin-left: 10px;
    border-width: 1px;
    border-color: gray;
    border-style: solid;
}
.btnArea{
    border-radius:5px;
    border-width:1px;
    border-style: solid;
    border-color:gray;
    padding:3px;
    width:60px;
    min-width: 60px;
    margin-right:10px;
}
.btnAreaActive{
    border-radius:5px;
    border-style: solid;
    border-width:1px;
    border-color:red;
    padding:3px;
    width:60px;
    min-width: 60px;
    margin-right:10px;
}
.rowArea{
    display: flex;
    flex-direction: row;
    overflow: auto;
    margin: 10px 0px;
    width: fit-content;
    align-self: center;
    margin: 10px auto;
    max-width: 100%;
}
.groupTime{
    background-color: #ececec;
    padding:5px;
    margin: 5px 0px 5px 0px;
    
}
.groupTime > span{
    font-weight: bold;
    font-size: 16px;
}
.rowTickets{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.listKienThiet{
    width:33.3%; 
    display: flex;
    text-align: center;
    justify-content: center;
}
.txtTicket{
    font-weight:bold;
    color:#000;
    font-size: 13px;
    margin:0px 3px
}
.txtTicketActive{
    color:red;

}
.numberThree{
display: flex;
flex-direction: column;
}
.numberThreeText {
    font-size: 13px;
}
.txtRemain{
    font-size: 13px;
    background-color:red;
    border-radius:5;
    padding:0px 3px ;
    color:#fff
}
.txtRemainHide{
    font-size: 13px;
    background-color:#fff;
    border-radius:5;
    padding:0px 3px ;
    color:#fff
}
.numberList {
    border-radius: 5px;
    letter-spacing: 1px;
    margin: 5px;
    display: flex;
    font-size: 17px;
    border-width: 1px;
    margin-left: 5px;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    display: flex;
    border-style: solid;
}
.numberListActive {
    border-color: red;
}
.container{
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.containerTicket{
    flex:1;
    overflow-y: auto;
}
.priceContainer{
    background-color:#fff;
    padding:20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px -2px 3px 0px #938d8d;
    z-index: 9;
}
.priceContainer div{
    display: flex;
    flex-direction: column;
}
.modalText {
    margin-bottom: 15px;
    text-align: center;
    color:'#000';
}
.flatListCenter {    
    text-align: center;
    justify-content: center;
    padding-left: 10;
}
.textNumberEnable {
    font-size: 20px;
    color: red;
    height: 30px;
    width: 30px;
    text-align: center;
    border-radius: 50%;
    border-color: red;
    border-style: solid;
    border-width: 1px;
    margin-right: 8px;
    margin-bottom: 10px;
}
.textNumberEnableActive{
    color:#fff;
    background-color: red;
}
.btnConfirm{
    background-color: purple;
    border-radius: 20px;
    padding: 10px;
    width: 100px;
    margin-top:10px;
}
.inputBox > input:focus{
    outline: none;
}