.boardResult {
    margin-top:4;
    flex:1;
    font-size: 15px;
    margin:5px
  }
  
  .row {
    flex-direction: row !important;
    border-bottom-width: 1px;
    border-style:solid;
    border-color:gray;
  }
  .column {
    width: 20%;
    height: auto;
    padding: 6px;
    align-self: center
  }
  .listProvince {
    flex: 1;
    border-left-width: 1px;
    border-color:gray;
    align-items:center;
    padding: 4px;
  }
  .province {
    flex: 1;
    text-align: center;
    color: red;
    font-weight: 500px;
  }
  .number {
    flex: 1;
    text-align: center;
    color: '#000';
    font-weight: 500px;
    padding: 6px;
    align-self: center;
  }
  .numberDb {
    flex: 1;
    text-align: center;
    color: red;
    font-weight: '500';

    padding: 6px;
  }

  .namePrize {
    color: black;
    font-weight: 500px;
    text-align: center;
  }
  .namePrizeDB {
    color: red;
    font-weight: 500px;
    text-align: center;
      
  }
  .colPrizeSix {
    flex: 1;
    font-weight:500px;
    text-align: center;
    color:#000;
  }
  .noData{
    text-align:center;
    font-size:16px;
    margin-top:20%;
  }

  .boardResult div
  {
    display: flex;
    flex-direction: column;
    }   