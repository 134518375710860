.profile-page {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    .profile-content {
        height: 100%;
        overflow-y: auto;
    }
}
