.input-otp {
    min-height: 60px;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: red;
}


.app-download {

    -webkit-box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.1);
    background-color: white;

    .btn-close {
        position: absolute;
        top: 5px;
        right: 0;
        color: orange;
    }

    img {
        transition: all .2s ease-in-out;

        &:hover {
            transform: scale(1.2);
        }
    }
}

.feature-page-container {
    //padding-left: -15px !important;
    //padding-right: -15px !important;
    //margin-left: -15px !important;
    //margin-right: -15px !important;
    font-size: 0.875rem;
}

.feature-page-item {
    div:first-child {
        margin-right: 5px;
    }
    margin-top: 5px;
    margin-bottom: 5px;
}
.feature-page-item-2 {
    div:first-child {
        margin-right: 5px;
    }
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #a180da;
    border-color: #a180da;
    display: inline-block;
    vertical-align:middle;
    border-radius: 0.3rem;
}
.feature-page-item-3 {
    border: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
    display: flex;
}
.pH{
    padding:0 20px 0px 20px
}