.main {
  width: 100%;
  min-height: -webkit-fill-available;;
  height: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  transition: $sidebar-transition;
  background-color: white;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
  @include media-breakpoint-down(md) {
    overflow-y: hidden;
  }
}
