.content {

    height: 100%;

    overflow-y: auto;

    //padding: $content-padding;

    //padding-top: 85px;

    @include media-breakpoint-down(md) {
        width: 100%;

    }

    //@include media-breakpoint-down(lg) {
    //    padding: $spacer*1.5;
    //}
}
