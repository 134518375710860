.avatar {
  margin-top: -15px;
  margin-bottom: -15px;
  width: 40px;
  height: 40px;
}

.avatar-circle-1x {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}


.avatar-circle-2x {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
